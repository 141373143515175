.NavMainContainer {
  width: 135px;
  /* height: calc(100vh - 80px); */
  overflow-y: auto;
  display: flex;
  position: absolute;
  margin-top: 60px;
  left: 40px;
  overflow: visible;
}

.NavMainContainer::-webkit-scrollbar {
  width: 4px;
  border-radius: 20px;
}

.NavMainContainer::-webkit-scrollbar-track {
  background: transparent;
}

.NavMainContainer::-webkit-scrollbar-thumb {
  background: transparent;
}

.NavMainContainer:hover::-webkit-scrollbar-thumb {
  background: #b99e71b5;
}

.NavMainContainer > div {
  width: 100%;
}

.NavMainContainer ul {
  padding: 0;
}

.vertical-menu {
  width: 130px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  margin: auto;
  transition: all 0.6s ease-in-out;
  height: calc(100vh - 100px);
  padding-top: 30px;
}

.stickyNav {
  position: fixed;
  top: 0;
  min-height: calc(100vh - 0px);
  /* padding-top: 65px; */
  padding-top: 15px;
  z-index: 1;
  /* width: 135px; */
  transition: 0.44s;
  overflow: hidden;
  overflow-y: auto;
}

.stickyNav:hover {
  overflow: hidden;
  overflow-y: auto;
  /* width: 135px; */
}
.stickyNav::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.stickyNav::-webkit-scrollbar-track {
  background: transparent; /* Track color */
  border-radius: 10px;
}
.stickyNav:hover::-webkit-scrollbar-track {
  background: #46455b;
}

.stickyNav::-webkit-scrollbar-thumb {
  background: transparent; /* Scrollbar color */
  border-radius: 8px;
}
.stickyNav:hover::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar color */
  border-radius: 8px;
}

.stickyNav::-webkit-scrollbar-thumb:hover {
  background: #555; /* Scrollbar color on hover */
}

.relative {
  position: relative;
}

.vertical-menu ul li > a {
  padding: 10px 0px;
  text-decoration: none;
  font-size: 12px;
  color: var(--primarycolor);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.vertical-menu ul li > a img {
  width: 20px;
  margin-bottom: 8px;
}

.vertical-menu ul li > a span {
  margin-top: 4px;
  text-transform: uppercase;
  letter-spacing: 1.7px;
}

.vertical-menu ul li > a:hover,
.vertical-menu ul li > a.active {
  color: #fff;
}

.vertical-menu ul li > a.disabled-link {
  color: gray;
}

.vertical-menu ul li > a:hover img,
.vertical-menu ul li > a.active img {
  filter: brightness(0) invert(1);
}

.menu-toggle {
  display: none;
}

.SubMenuItemBox {
  width: 190px;
  border-radius: 8px;
  background-color: rgb(34 23 42) !important;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
  color: var(--primarycolor);
  /* position: absolute; */
  position: fixed;
  text-align: left;
  /* left: 105px; */
  left: 157px;
  margin-top: -60px;
  transform: translateX(20px);
  opacity: 0;
  height: 0;
  overflow: hidden;
}

#ReportSub:hover .SubMenuItemBox {
  transform: translateX(0);
  opacity: 1;
  padding: 12px 0px !important;
  height: auto;
  transition: opacity 0.8s ease, transform 0.8s ease, height 0.8s ease;
}

.SubMenuItemBox a {
  font-size: 13px;
  color: var(--primarycolor);
  align-items: flex-start !important;
  padding: 3px 18px !important;
}

.afterinfo {
  z-index: 1;
  position: absolute;
  transform: rotate(-135deg);
  background: rgb(34 23 42) !important;
  right: 0px;
  top: 28px;
  height: 0;
  width: 0;
  transition: 0.4s ease;
}

#ReportSub:hover .afterinfo {
  width: 12px;
  height: 12px;
}

/* ************************************************************************************ */
@media screen and (max-width: 1367px) and (max-height: 681px) {
  .vertical-menu ul li > a {
    padding: 5px 0px;
    font-size: 11px;
  }
  .vertical-menu ul li > a img {
    width: 18px;
    margin-bottom: 5px;
  }
}
/* ************************************************************************************ */

@media (max-width: 992px) {
  .SubMenuItemBox {
    left: 115px;
  }

  .afterinfo {
    right: -2px;
  }

  .vertical-menu {
    margin-left: -400px;
    flex-direction: column;
    transition: 0.4s all ease-in-out;
    top: 0;
    position: fixed;
    height: 100vh;
    width: 157px;
    z-index: 999;
    background-color: #30131c;
    width: 120px;
    padding: 20px 0;
  }

  .vertical-menu.open {
    margin-left: 0;
    top: 0;
    left: 0;
    transition: 0.4s all ease-in-out;
  }

  .vertical-menu ul > li {
    padding: 0px 20px;
  }

  .vertical-menu ul > li > ul li {
    padding: 0px 0px;
  }

  .menu-toggle {
    display: block;
    color: white;
    cursor: pointer;
    z-index: 99;
    position: absolute;
    right: 24px;
    top: 24px;
  }

  .sidenavCloseBtn {
    position: relative;
    z-index: 12;
    left: 150px;
  }
}
