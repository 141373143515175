.resetMain {
    height: calc(100vh - 240px);
    display: flex;
    align-items: center;
}
.resetBox {
    background: #14182769;
    padding: 30px;
    max-width: 400px;
    width: 100%;
    border-radius: 9px;
    position: relative;
}
.resetBox input{
    color: #fff;
}
.registerAlert
 {
    background-color: #463757;
    color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
}
.registerAlertBox .MuiPaper-elevation
{
    background-color: transparent !important;
}