.uerPopup .MuiDialog-paper {
  background-color: #22172a !important;
}

.uerPopupBorder .MuiDialog-paper {
  border: 1px solid #372643;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #372643a8 !important;
}
.uerPopup .popupFeild input:hover ~ fieldset,
.uerPopup .popupFeild select:hover ~ fieldset,
.uerPopup .popupFeild textarea:hover ~ fieldset,
.userclndr .MuiInputBase-fullWidth {
  border-color: rgb(147 148 149 / 62%) !important;
}
.uerPopup input,
.uerPopup select,
.uerPopup textarea {
  color: #fff !important;
}
.uerPopup .MuiDialog-paper {
  background-color: #22172a !important;
}
.uerPopupBorder .MuiDialog-paper {
  border: 1px solid #372643;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #372643a8 !important;
}
.uerPopupBorder .MuiDialog-paper {
  border: 1px solid #372643;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #372643a8 !important;
}
.butonstyles3 {
  width: 100%;
  padding: 15px;
  background-color: var(--navlink);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 18px;
  text-transform: uppercase;
}

.butonstyle3:hover {
  background-color: #40331c;
  /* Example hover color */
}
