
@font-face {
    font-family: "apercu_promedium";
    src: url("../fonts/apercu_pro_medium-webfont.woff2") format("woff2"),
      url("../fonts/apercu_pro_medium-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "apercuregular";
    src: url("../fonts/apercu_regular-webfont.woff2") format("woff2"),
      url("../fonts/apercu_regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "apercu_pro_light";
    src: url("../fonts/apercu_pro_light-webfont.woff2") format("woff2"),
      url("../fonts/apercu_pro_light-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "baskerville_classicoitalic";
    src: url("../fonts/baskerville_classico_italic-webfont.woff2") format("woff2"),
      url("../fonts/baskerville_classico_italic-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }